import dotenv from "dotenv"
dotenv.config()

let APPLICATION_ID
let backendURL
let firebaseConfig

const homeURL = "https://mingle-portal.inforcloudsuite.com/PRECIPART_PRD"
const environment = process.env.REACT_APP_ENV

if (process.env.REACT_APP_ENV === "development") {
    APPLICATION_ID = "1"
    backendURL = "http://localhost:5000"

    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
} else if (process.env.REACT_APP_ENV === "gcpdevelopment") {
    APPLICATION_ID = "1"
    backendURL =
        "https://application-administration-server-tdnsdphoqq-uk.a.run.app/"

    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
} else if (process.env.REACT_APP_ENV === "test") {
    APPLICATION_ID = "1"
    backendURL =
        "https://application-administration-server-b34iv6u2nq-uk.a.run.app/"

    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
} else if (process.env.REACT_APP_ENV === "production") {
    APPLICATION_ID = "1"
    backendURL =
        "https://application-administration-server-jaf747essq-uk.a.run.app/"

    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
}

export { APPLICATION_ID, backendURL, firebaseConfig, homeURL, environment }
