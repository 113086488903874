import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
    Grid,
    Card,
    IconButton,
    Tooltip,
    CardHeader,
    Avatar,
    Collapse,
    CardActions,
    CardContent,
    Typography
} from "@material-ui/core"
import { styled } from "@material-ui/styles"
import LaunchIcon from "@mui/icons-material/Launch"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import useStyles from "./styles/ApplicationCardStyles"

// ExpandMore Component
const ExpandMore = styled((props) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
    })
}))

function ApplicationCard(props) {
    const classes = useStyles()

    // Props
    const {
        applicationID,
        application,
        applicationURL,
        releaseNotesURL,
        majorVersion,
        minorVersion,
        fixVersion
    } = props

    // Store Variables
    const applications = useSelector((state) => state.applications.applications)

    // Application Card State
    const [expanded, setExpanded] = useState(false)

    // Application Card Handlers
    const handleClickStartApplication = async () => {
        window.open(applicationURL, "_self")
    }

    const handleClickReleaseNotes = () => {
        releaseNotesURL && window.open(releaseNotesURL, "_blank")
    }

    const handleClickExpand = () => {
        setExpanded(!expanded)
    }

    return (
        <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.ApplicationCard}>
                <CardHeader
                    className={classes.ApplicationCardHeader}
                    avatar={<Avatar>{application.charAt(0)}</Avatar>}
                    title={application}
                />
                <CardActions>
                    <Typography
                        variant="subtitle1"
                        className={classes.Version}
                    >{`v${majorVersion}.${minorVersion}.${fixVersion}`}</Typography>
                    <div className={classes.ApplicationButtons}>
                        {/* Start Application Button */}
                        <Tooltip title="Start Application">
                            <IconButton onClick={handleClickStartApplication}>
                                <LaunchIcon />
                            </IconButton>
                        </Tooltip>
                        {/* Release Notes Button */}
                        <Tooltip title="Release Notes">
                            <IconButton onClick={handleClickReleaseNotes}>
                                <FormatListBulletedIcon />
                            </IconButton>
                        </Tooltip>
                        {/* Expand More Button */}
                        <ExpandMore
                            expand={expanded}
                            onClick={handleClickExpand}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <Tooltip title="More Details">
                                <ExpandMoreIcon />
                            </Tooltip>
                        </ExpandMore>
                    </div>
                </CardActions>

                {/* Collapse Content */}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        {/* Application Owner */}
                        <Typography
                            variant="subtitle2"
                            className={classes.DetailTitle}
                        >
                            Owner -{" "}
                            {applications.filter(
                                (application) =>
                                    application.ApplicationID === applicationID
                            )[0] &&
                                applications.filter(
                                    (application) =>
                                        application.ApplicationID ===
                                        applicationID
                                )[0].ApplicationOwner}
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    )
}

export default ApplicationCard
