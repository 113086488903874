import {
    GET_PERMISSIONS_FOR_APPLICATION,
    CREATE_PERMISSION,
    DELETE_PERMISSION
} from "../actions/types"

const initialState = {
    selectedApplicationPermissions: []
}

const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PERMISSIONS_FOR_APPLICATION:
            return { ...state, selectedApplicationPermissions: action.payload }
        case CREATE_PERMISSION:
            return { ...state }
        case DELETE_PERMISSION:
            return { ...state }
        default:
            return state
    }
}

export default permissionReducer
