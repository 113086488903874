import {
    GET_PERMISSIONS_FOR_APPLICATION,
    CREATE_PERMISSION,
    DELETE_PERMISSION
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getPermissionsForApplication =
    (applicationID) => async (dispatch) => {
        const res = await axios.get(
            `${backendURL}/permission/application/${applicationID}`
        )
        dispatch({ type: GET_PERMISSIONS_FOR_APPLICATION, payload: res.data })
    }

export const createPermission = (newPermission) => async (dispatch) => {
    await axios.post(`${backendURL}/permission`, newPermission)
    dispatch({ type: CREATE_PERMISSION })
}

export const deletePermission = (permissionID) => async (dispatch) => {
    await axios.delete(`${backendURL}/permission/${permissionID}`)
    dispatch({ type: DELETE_PERMISSION })
}
