import {
    GET_PENDING_USERS,
    CREATE_PENDING_USER,
    DELETE_PENDING_USER
} from "../actions/types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getPendingUsers = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/pending-user`)
    dispatch({ type: GET_PENDING_USERS, payload: res.data })
}

export const createPendingUser = (newPendingUser) => async (dispatch) => {
    await axios.post(`${backendURL}/pending-user`, newPendingUser)
    dispatch({ type: CREATE_PENDING_USER })
}

export const deletePendingUser = (googleID) => async (dispatch) => {
    await axios.delete(`${backendURL}/pending-user/${googleID}`)
    dispatch({ type: DELETE_PENDING_USER })
}
