import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    Application: {
        marginBottom: theme.spacing(2)
    },
    FirstApplicationItem: {
        padding: "8px"
    },
    ApplicationItem: {
        paddingLeft: "8px",
        paddingBottom: "6px"
    },
    LastApplicationItem: {
        paddingLeft: "8px"
    },
    ApplicationButtons: {
        marginTop: "auto",
        marginBottom: "2px"
    },
    Dropdown: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    Toggle: {
        marginTop: theme.spacing(4)
    }
}))

export default useStyles
