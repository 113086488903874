import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    NewApplication: {
        marginBottom: theme.spacing(3)
    },
    NewApplicationButton: {
        marginTop: theme.spacing(2),
        marginBottom: "10px"
    },
    Dropdown: {
        marginTop: theme.spacing(4)
    }
}))

export default useStyles
