import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    DialogContentText,
    LinearProgress
} from "@material-ui/core"
import { Stack } from "@mui/material"
import useStyles from "./styles/NewUserStyles"
import { getEmployeesWithoutApplicationAccess } from "../../actions/employeeActions"
import { createUser, getUsers } from "../../actions/userActions"
import {
    getPendingUsers,
    deletePendingUser
} from "../../actions/pendingUserActions"

function NewUser() {
    const classes = useStyles()
    const dispatch = useDispatch()

    // Store Variables
    const employeesWithoutApplicationAccess = useSelector(
        (state) => state.employees.employeesWithoutApplicationAccess
    )
    const pendingUsers = useSelector((state) => state.pendingUsers.pendingUsers)

    // Loading State
    const [loading, setLoading] = useState(false)

    // Add User Dialog State
    const [addUserDialogOpen, setAddUserDialogOpen] = useState(false)
    const [addUserDialogValues, setAddUserDialogValues] = useState({
        associateOID: "",
        googleID: "",
        selectedGoogleID: ""
    })

    // Add User Dialog Handlers
    const handleOpenAddUserDialog = () => {
        setAddUserDialogOpen(true)
        setAddUserDialogValues({
            ...addUserDialogValues,
            associateOID:
                employeesWithoutApplicationAccess &&
                employeesWithoutApplicationAccess[0].AssociateOID
        })
    }

    const handleCloseAddUserDialog = () => {
        setAddUserDialogOpen(false)
        clearAddUserDialogValues()
    }

    const handleChangeAddUserDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setAddUserDialogValues({
            ...addUserDialogValues,
            [name]: value
        })
    }

    const clearAddUserDialogValues = () => {
        setAddUserDialogValues({
            associateOID: "",
            googleID: "",
            selectedGoogleID: ""
        })
    }

    const handleClickSave = async () => {
        if (addUserDialogValues.associateOID !== "") {
            setLoading(true)
            if (
                addUserDialogValues.selectedGoogleID === "" ||
                addUserDialogValues.selectedGoogleID === "none"
            ) {
                const newUser = {
                    AssociateOID: addUserDialogValues.associateOID,
                    GoogleID: addUserDialogValues.googleID,
                    FromPendingUser: false
                }
                await dispatch(createUser(newUser))
            } else {
                const newUser = {
                    AssociateOID: addUserDialogValues.associateOID,
                    GoogleID: addUserDialogValues.selectedGoogleID,
                    FromPendingUser: true
                }
                await dispatch(createUser(newUser))
                await dispatch(
                    deletePendingUser(addUserDialogValues.selectedGoogleID)
                )
            }
            await dispatch(getUsers())
            await dispatch(getEmployeesWithoutApplicationAccess())
            await dispatch(getPendingUsers())
            handleCloseAddUserDialog()
            setLoading(false)
        }
    }

    return (
        <>
            <Button
                variant="contained"
                className={classes.NewUserButton}
                onClick={handleOpenAddUserDialog}
            >
                Add New App Profile (Existing Firebase User)
            </Button>

            {/* Add User Dialog */}
            <Dialog
                open={addUserDialogOpen}
                onClose={handleCloseAddUserDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    Add New App Profile (Existing Firebase User)
                </DialogTitle>
                <DialogContent>
                    {/* User Field */}
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.EmployeeNameDropdown}
                    >
                        <InputLabel htmlFor="associateOID">
                            Employee Name
                        </InputLabel>
                        <Select
                            native
                            value={addUserDialogValues.associateOID}
                            onChange={handleChangeAddUserDialog}
                            label="Employee Name"
                            inputProps={{
                                name: "associateOID",
                                id: "associateOID"
                            }}
                        >
                            {employeesWithoutApplicationAccess &&
                                employeesWithoutApplicationAccess.map(
                                    (employee) => (
                                        <option
                                            key={employee.AssociateOID}
                                            value={employee.AssociateOID}
                                        >
                                            {`${employee.FirstName} ${employee.LastName}`}
                                        </option>
                                    )
                                )}
                        </Select>
                    </FormControl>
                    <DialogContentText>
                        Either select an email from the Pending User dropdown or
                        enter a Google ID manually to assign a Google ID to the
                        new user
                    </DialogContentText>
                    {/* Pending User Field */}
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.PendingUserDropdown}
                    >
                        <InputLabel htmlFor="selectedGoogleID">
                            Pending User
                        </InputLabel>
                        <Select
                            native
                            value={addUserDialogValues.selectedGoogleID}
                            onChange={handleChangeAddUserDialog}
                            label="Pending User"
                            inputProps={{
                                name: "selectedGoogleID",
                                id: "selectedGoogleID"
                            }}
                        >
                            <option key="none" value="none">
                                Select Google ID from a Pending User
                            </option>
                            {pendingUsers &&
                                pendingUsers.map((pendingUser) => (
                                    <option
                                        key={pendingUser.Email}
                                        value={pendingUser.GoogleID}
                                    >
                                        {pendingUser.Email}
                                    </option>
                                ))}
                        </Select>
                        <DialogContentText className={classes.OR}>
                            OR
                        </DialogContentText>
                    </FormControl>
                    {/* Google ID Field */}
                    <TextField
                        margin="dense"
                        id="googleID"
                        label="Enter Google ID Manually"
                        type="text"
                        name="googleID"
                        value={addUserDialogValues.googleID}
                        onChange={handleChangeAddUserDialog}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddUserDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleClickSave}
                        color="primary"
                        disabled={loading}
                    >
                        <Stack>
                            Save
                            {loading && <LinearProgress />}
                        </Stack>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewUser
