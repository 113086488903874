import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    ApplicationCard: {
        margin: theme.spacing(2)
    },
    ApplicationCardHeader: {
        height: "80px"
    },
    ApplicationButtons: {
        marginLeft: "auto !important"
    },
    DetailTitle: {
        marginBottom: theme.spacing(1)
    },
    Permission: {
        marginLeft: theme.spacing(1)
    },
    Version: {
        paddingLeft: theme.spacing(2)
    }
}))

export default useStyles
