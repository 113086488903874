import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Grid, Container } from "@material-ui/core"
import LoginPrompt from "../login/LoginPrompt"
import ApplicationCard from "./ApplicationCard"
import { getApplications } from "../../actions/applicationActions"

function Home() {
    const dispatch = useDispatch()

    // Store Variables
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const authenticated = useSelector((state) => state.auth.authenticated)
    const applications = useSelector((state) => state.applications.applications)

    useEffect(() => {
        if (loggedIn) {
            async function runUseEffectFunctions() {
                await dispatch(getApplications())
            }
            runUseEffectFunctions()
        }
        return () => {}
    }, [dispatch, loggedIn])

    return (
        <>
            <Container>
                {authenticated ? (
                    <Grid container justify="center">
                        {/* Application Cards */}
                        <Grid container>
                            {applications
                                .filter(
                                    (application) =>
                                        application.ApplicationIsVisible
                                )
                                .map((application) => (
                                    <ApplicationCard
                                        key={application.ApplicationID}
                                        applicationID={
                                            application.ApplicationID
                                        }
                                        application={application.Application}
                                        applicationURL={
                                            application.ApplicationURL
                                        }
                                        releaseNotesURL={
                                            application.ReleaseNotesURL
                                        }
                                        majorVersion={application.MajorVersion}
                                        minorVersion={application.MinorVersion}
                                        fixVersion={application.FixVersion}
                                    />
                                ))}
                        </Grid>
                    </Grid>
                ) : (
                    <LoginPrompt />
                )}
            </Container>
        </>
    )
}

export default Home
