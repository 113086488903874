import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Grid,
    Typography,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    LinearProgress
} from "@material-ui/core"
import { Stack } from "@mui/material"
import useStyles from "./styles/NewApplicationStyles"
import Alert from "../main/Alert"
import {
    getApplications,
    createApplication
} from "../../actions/applicationActions"

function NewApplication() {
    const classes = useStyles()
    const dispatch = useDispatch()

    // Store Variables
    const employees = useSelector((state) => state.employees)

    // Alert State
    const [alertDialog, setAlertDialog] = useState(false)
    const [alertText, setAlertText] = useState("")

    // Loading State
    const [loading, setLoading] = useState(false)

    // Add Application Dialog State
    const [addApplicationDialogOpen, setAddApplicationDialogOpen] =
        useState(false)
    const [addApplicationDialogValues, setAddApplicationDialogValues] =
        useState({
            application: "",
            applicationURL: "",
            releaseNotesURL: "",
            applicationOwnerAOID: ""
        })

    // Alert Handlers
    const handleAlertDialogClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertDialog(false)
    }

    // Add Application Dialog Handlers
    const handleOpenAddApplicationDialog = () => {
        setAddApplicationDialogOpen(true)
    }

    const handleCloseAddApplicationDialog = () => {
        setAddApplicationDialogOpen(false)
        clearAddApplicationDialogValues()
    }

    const handleChangeAddApplicationDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setAddApplicationDialogValues({
            ...addApplicationDialogValues,
            [name]: value
        })
    }

    const clearAddApplicationDialogValues = () => {
        setAddApplicationDialogValues({
            application: "",
            applicationURL: "",
            releaseNotesURL: "",
            applicationOwnerAOID: ""
        })
    }

    const handleClickSave = async () => {
        if (addApplicationDialogValues.application === "") {
            setAlertText("Application field must be filled out")
            setAlertDialog(true)
        } else {
            setLoading(true)
            const newApplication = {
                Application: addApplicationDialogValues.application,
                ApplicationURL: addApplicationDialogValues.applicationURL,
                ReleaseNotesURL: addApplicationDialogValues.releaseNotesURL,
                ApplicationOwnerAOID:
                    addApplicationDialogValues.applicationOwnerAOID
            }
            await dispatch(createApplication(newApplication))
            await dispatch(getApplications())
            handleCloseAddApplicationDialog()
            setLoading(false)
        }
    }

    return (
        <Grid
            item
            xs={10}
            md={8}
            className={classes.NewApplication}
            align="center"
        >
            <Typography variant="h5" align="center">
                Applications
            </Typography>

            <Button
                variant="contained"
                className={classes.NewApplicationButton}
                onClick={handleOpenAddApplicationDialog}
            >
                Add A New Application
            </Button>

            {/* Add Application Dialog */}
            <Dialog
                open={addApplicationDialogOpen}
                onClose={handleCloseAddApplicationDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    New Application
                </DialogTitle>
                <DialogContent>
                    {/* Application Field */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="application"
                        label="Application"
                        type="text"
                        name="application"
                        value={addApplicationDialogValues.application}
                        onChange={handleChangeAddApplicationDialog}
                        fullWidth
                    />
                    {/* Applicaiton URL Field */}
                    <TextField
                        margin="dense"
                        id="applicationURL"
                        label="Application URL"
                        type="text"
                        name="applicationURL"
                        value={addApplicationDialogValues.applicationURL}
                        onChange={handleChangeAddApplicationDialog}
                        fullWidth
                    />
                    {/* Release Notes URL Field */}
                    <TextField
                        margin="dense"
                        id="releaseNotesURL"
                        label="Release Note URL"
                        type="text"
                        name="releaseNotesURL"
                        value={addApplicationDialogValues.releaseNotesURL}
                        onChange={handleChangeAddApplicationDialog}
                        fullWidth
                    />
                    {/* Application Owner Field */}
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.Dropdown}
                    >
                        <InputLabel htmlFor="applicationOwnerAOID">
                            Application Owner
                        </InputLabel>
                        <Select
                            native
                            value={
                                addApplicationDialogValues.applicationOwnerAOID
                            }
                            onChange={handleChangeAddApplicationDialog}
                            label="Application Owner"
                            inputProps={{
                                name: "applicationOwnerAOID",
                                id: "applicationOwnerAOID"
                            }}
                        >
                            <option key="none" value="none">
                                Select an Application Owner
                            </option>
                            {employees.activeEmployees.map((employee) => (
                                <option
                                    key={employee.AssociateOID}
                                    value={employee.AssociateOID}
                                >
                                    {`${employee.FirstName} ${employee.LastName}`}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseAddApplicationDialog}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleClickSave}
                        color="primary"
                        disabled={loading}
                    >
                        <Stack>
                            Save
                            {loading && <LinearProgress />}
                        </Stack>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Alert Dialog */}
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={alertDialog}
                    autoHideDuration={5000}
                    onClose={handleAlertDialogClose}
                >
                    <Alert onClose={handleAlertDialogClose} severity="error">
                        {alertText}
                    </Alert>
                </Snackbar>
            </div>
        </Grid>
    )
}

export default NewApplication
