import {
    GET_PENDING_USERS,
    CREATE_PENDING_USER,
    DELETE_PENDING_USER
} from "../actions/types"

const initialState = {
    pendingUsers: []
}

const pendingUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PENDING_USERS:
            return { ...state, pendingUsers: action.payload }
        case CREATE_PENDING_USER:
            return { ...state }
        case DELETE_PENDING_USER:
            return { ...state }
        default:
            return state
    }
}

export default pendingUserReducer
