import {
    CREATE_AUTHENTICATION,
    CREATE_AUTHORIZATION,
    DELETE_AUTHENTICATION,
    DELETE_AUTHORIZATION,
    GET_USERS,
    CREATE_USER,
    CREATE_FIREBASE_USER,
    UPDATE_USER,
    DELETE_USER
} from "../actions/types"

const initialState = {
    users: []
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS:
            return { ...state, users: action.payload }
        case CREATE_USER:
            return { ...state }
        case CREATE_FIREBASE_USER:
            return { ...state }
        case UPDATE_USER:
            return { ...state }
        case DELETE_USER:
            return { ...state }
        case CREATE_AUTHENTICATION:
            return { ...state }
        case DELETE_AUTHENTICATION:
            return { ...state }
        case CREATE_AUTHORIZATION:
            return { ...state }
        case DELETE_AUTHORIZATION:
            return { ...state }
        default:
            return state
    }
}

export default userReducer
