import React, { useEffect, useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import axios from "../../auth/axiosAuth"
import { backendURL } from "../../config"
import { Button, Typography, LinearProgress } from "@material-ui/core"
import Stack from "@mui/material/Stack"
import "firebase/compat/auth"
import firebase from "firebase/compat/app"
import { APPLICATION_ID } from "../../config"
import firebaseAuth from "../../auth/firebaseAuth"
import {
    getUserData,
    getAuthorizationForCurrentApplication,
    loginUser
} from "../../actions/authActions"
import { createPendingUser } from "../../actions/pendingUserActions"
import { createAuditTrail } from "../../actions/auditTrailActions"

function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const userAuth = useSelector((state) => state.auth.user)

    const userExists = useCallback(
        async (user) => {
            const res = await axios.get(`${backendURL}/user/google/${user.uid}`)

            if (res.data.length > 0) {
                const associateOID = res.data[0].AssociateOID
                await dispatch(getUserData(user.uid))
                await dispatch(
                    getAuthorizationForCurrentApplication(associateOID)
                )
                const resAuth = await axios.get(
                    `${backendURL}/authorization/user/application/${associateOID}/${APPLICATION_ID}`
                )
                if (resAuth.data?.length > 0) {
                    // If User Has Permission
                    const newAuditTrail = {
                        AssociateOID: associateOID,
                        ApplicationID: APPLICATION_ID,
                        Action: "Start Application",
                        Object: APPLICATION_ID
                    }
                    dispatch(createAuditTrail(newAuditTrail))
                    navigate("/home")
                } else {
                    // If User Does Not Have Permission
                    navigate("/user-not-found")
                }
            }
        },
        [dispatch, navigate]
    )

    useEffect(() => {
        const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
            if (user) {
                dispatch(loginUser(user))
            }
        })

        if (firebaseAuth.currentUser) {
            userExists(userAuth)
        }

        return () => {
            unsubscribe()
        }
    }, [dispatch, userAuth, userExists])

    const handleClickLogin = async () => {
        const provider = new firebase.auth.OAuthProvider("microsoft.com")
        provider.setCustomParameters({ prompt: "select_account" })

        await firebaseAuth
            .signInWithPopup(provider)
            .then(async (props) => {
                const res = await axios.get(
                    `${backendURL}/user/google/${props.user.uid}`
                )
                if (res.data.length > 0) {
                    // If User Exists

                    const associateOID = res.data[0].AssociateOID
                    await dispatch(getUserData(props.user.uid))
                    await dispatch(
                        getAuthorizationForCurrentApplication(associateOID)
                    )
                    const resAuth = await axios.get(
                        `${backendURL}/authorization/user/application/${associateOID}/${APPLICATION_ID}`
                    )
                    console.log(resAuth.data)
                    if (resAuth.data?.length > 0) {
                        // If User Has Permission
                        const newAuditTrail = {
                            AssociateOID: associateOID,
                            ApplicationID: APPLICATION_ID,
                            Action: "Start Application",
                            Object: APPLICATION_ID
                        }
                        dispatch(createAuditTrail(newAuditTrail))
                        navigate("/home")
                    } else {
                        // If User Does Not Have Permission
                        navigate("/user-not-found")
                    }
                } else {
                    // If User Does Not Exist
                    const existingUsers = await axios.get(
                        `${backendURL}/pending-user/${props.user.email}`
                    )
                    if (existingUsers.data.length < 1) {
                        const newPendingUser = {
                            Email: props.user.email,
                            GoogleID: props.user.uid
                        }
                        await dispatch(createPendingUser(newPendingUser))
                    }
                    navigate("/user-not-found")
                }
            })
            .catch((error) => console.log(error))
        setLoading(false)
    }

    return (
        <Button
            variant="contained"
            onClick={handleClickLogin}
            disabled={loading}
        >
            <Stack>
                <Typography variant="h4">Login</Typography>

                {loading && <LinearProgress />}
            </Stack>
        </Button>
    )
}

export default Login
