import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    User: {
        marginBottom: theme.spacing(2)
    },
    UserName: {
        marginTop: "8px",
        marginLeft: "14px",
        marginBottom: "8px"
    },
    Dropdown: {
        marginBottom: theme.spacing(4)
    },
    AuthorizationTitle: {
        marginBottom: theme.spacing(1)
    },
    AuthorizationItem: {
        marginLeft: theme.spacing(3)
    }
}))

export default useStyles
