import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    LinearProgress
} from "@material-ui/core"
import { Stack } from "@mui/material"
import useStyles from "./styles/NewUserStyles"
import { getEmployeesWithoutApplicationAccess } from "../../actions/employeeActions"
import { createFirebaseUser, getUsers } from "../../actions/userActions"

function NewUser() {
    const classes = useStyles()
    const dispatch = useDispatch()

    // Store Variables
    const employeesWithoutApplicationAccess = useSelector(
        (state) => state.employees.employeesWithoutApplicationAccess
    )
    // Loading State
    const [loading, setLoading] = useState(false)

    // Add User Dialog State
    const [addUserDialogOpen, setAddUserDialogOpen] = useState(false)
    const [addUserDialogValues, setAddUserDialogValues] = useState({
        associateOID: "",
        azureID: ""
    })

    // Add User Dialog Handlers
    const handleOpenAddUserDialog = () => {
        setAddUserDialogOpen(true)
        setAddUserDialogValues({
            ...addUserDialogValues,
            associateOID:
                employeesWithoutApplicationAccess &&
                employeesWithoutApplicationAccess[0].AssociateOID
        })
    }

    const handleCloseAddUserDialog = () => {
        setAddUserDialogOpen(false)
        clearAddUserDialogValues()
    }

    const handleChangeAddUserDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setAddUserDialogValues({
            ...addUserDialogValues,
            [name]: value
        })
    }

    const clearAddUserDialogValues = () => {
        setAddUserDialogValues({
            associateOID: "",
            azureID: ""
        })
    }

    const handleClickSave = async () => {
        if (addUserDialogValues.associateOID !== "") {
            setLoading(true)
            const newUser = {
                AssociateOID: addUserDialogValues.associateOID,
                AzureID: addUserDialogValues.azureID
            }
            await dispatch(createFirebaseUser(newUser))
            await dispatch(getUsers())
            await dispatch(getEmployeesWithoutApplicationAccess())
            handleCloseAddUserDialog()
            setLoading(false)
        }
    }

    return (
        <>
            <Button
                variant="contained"
                className={classes.NewUserButton}
                onClick={handleOpenAddUserDialog}
            >
                Create New User (Firebase & App Profile)
            </Button>

            {/* Add User Dialog */}
            <Dialog
                open={addUserDialogOpen}
                onClose={handleCloseAddUserDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    Create New User (Firebase & App Profile)
                </DialogTitle>
                <DialogContent>
                    {/* User Field */}
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.EmployeeNameDropdown}
                    >
                        <InputLabel htmlFor="associateOID">
                            Employee Name
                        </InputLabel>
                        <Select
                            native
                            value={addUserDialogValues.associateOID}
                            onChange={handleChangeAddUserDialog}
                            label="Employee Name"
                            inputProps={{
                                name: "associateOID",
                                id: "associateOID"
                            }}
                        >
                            {employeesWithoutApplicationAccess &&
                                employeesWithoutApplicationAccess.map(
                                    (employee) => (
                                        <option
                                            key={employee.AssociateOID}
                                            value={employee.AssociateOID}
                                        >
                                            {`${employee.FirstName} ${employee.LastName}`}
                                        </option>
                                    )
                                )}
                        </Select>
                    </FormControl>
                    {/* Azure ID Field */}
                    <TextField
                        margin="dense"
                        id="azureID"
                        label="Enter Azure ID"
                        type="text"
                        name="azureID"
                        value={addUserDialogValues.azureID}
                        onChange={handleChangeAddUserDialog}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddUserDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleClickSave}
                        color="primary"
                        disabled={loading}
                    >
                        <Stack>
                            Save
                            {loading && <LinearProgress />}
                        </Stack>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewUser
