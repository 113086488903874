import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Grid,
    Card,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    IconButton,
    Tooltip,
    DialogContentText,
    TextField
} from "@material-ui/core"
import AppsIcon from "@mui/icons-material/Apps"
import SecurityIcon from "@mui/icons-material/Security"
import DeleteIcon from "@mui/icons-material/Delete"
import AddBoxIcon from "@mui/icons-material/AddBox"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import useStyles from "./styles/UserStyles"
import Alert from "../main/Alert"
import {
    createAuthentication,
    createAuthorization,
    getUsers,
    updateUser,
    deleteUser,
    deleteAuthentication,
    deleteAuthorization
} from "../../actions/userActions"
import { getPermissionsForApplication } from "../../actions/permissionActions"
import { getEmployeesWithoutApplicationAccess } from "../../actions/employeeActions"

function User(props) {
    const classes = useStyles()
    const dispatch = useDispatch()

    // Props
    const {
        associateOID,
        user,
        authentications,
        userApplications,
        userPermissions,
        darkMode,
        googleID
    } = props

    // Store Variables
    const auth = useSelector((state) => state.auth)
    const applications = useSelector((state) => state.applications)
    const permissions = useSelector((state) => state.permissions)

    // Alert State
    const [alertDialog, setAlertDialog] = useState(false)
    const [alertText, setAlertText] = useState("")

    // Edit Applications Dialog State
    const [editApplicationsDialogOpen, setEditApplicationsDialogOpen] =
        useState(false)
    const [editApplicationsDialogValues, setEditApplicationsDialogValues] =
        useState({
            applicationID: ""
        })

    // Edit User Dialog State
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false)
    const [editUserDialogValues, setEditUserDialogValues] = useState({
        googleID: ""
    })

    // Delete User Dialog State
    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false)
    const [deleteUserDialogValues, setDeleteUserDialogValues] = useState({
        user: ""
    })

    // Delete Authentication Dialog State
    const [deleteAuthenticationDialogOpen, setDeleteAuthenticationDialogOpen] =
        useState(false)
    const [
        selectedAuthenticationToBeDeleted,
        setSelectedAuthenticationToBeDeleted
    ] = useState({ authenticationID: 0, applicationID: 0 })

    // Edit Authorization Dialog State
    const [editAuthorizationDialogOpen, setEditAuthorizationDialogOpen] =
        useState(false)
    const [
        selectedApplicationToEditAuthorization,
        setSelectedApplicationToEditAuthorization
    ] = useState(0)

    // Alert Handlers
    const handleAlertDialogClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertDialog(false)
    }

    // Edit Applications Dialog Handlers
    const handleOpenEditApplicationsDialog = () => {
        setEditApplicationsDialogOpen(true)
    }

    const handleCloseEditApplicationsDialog = () => {
        setEditApplicationsDialogOpen(false)
        clearEditApplicationsDialogValues()
    }

    const handleChangeEditApplicationsDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setEditApplicationsDialogValues({
            ...editApplicationsDialogValues,
            [name]: value
        })
    }

    const clearEditApplicationsDialogValues = () => {
        setEditApplicationsDialogValues({
            applicationID: "none"
        })
    }

    const handleClickAddNewAuthentication = async () => {
        if (
            editApplicationsDialogValues.applicationID === "" ||
            editApplicationsDialogValues.applicationID === "none"
        ) {
            setAlertText("Application must be selected")
            setAlertDialog(true)
        } else {
            const newAuthentication = {
                AssociateOID: associateOID,
                ApplicationID: editApplicationsDialogValues.applicationID
            }
            await dispatch(createAuthentication(newAuthentication))
            await dispatch(getUsers())
            clearEditApplicationsDialogValues()
        }
    }

    // Edit User Dialog Handlers
    const handleOpenEditUserDialog = () => {
        setEditUserDialogOpen(true)
        setEditUserDialogValues({ ...editUserDialogValues, googleID })
    }

    const handleCloseEditUserDialog = () => {
        setEditUserDialogOpen(false)
        clearEditUserDialogValues()
    }

    const handleChangeEditUserDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setEditUserDialogValues({
            ...editUserDialogValues,
            [name]: value
        })
    }

    const clearEditUserDialogValues = () => {
        setEditUserDialogValues({
            googleID: ""
        })
    }

    const handleClickSaveUser = async () => {
        const updatedUser = {
            AssociateOID: associateOID,
            GoogleID: editUserDialogValues.googleID,
            DarkMode: darkMode
        }
        await dispatch(updateUser(updatedUser))
        await dispatch(getUsers())
        handleCloseEditUserDialog()
    }

    // Delete User Dialog Handlers
    const handleOpenDeleteUserDialog = () => {
        setDeleteUserDialogOpen(true)
    }

    const handleCloseDeleteUserDialog = () => {
        setDeleteUserDialogOpen(false)
    }

    const handleChangeDeleteUserDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setDeleteUserDialogValues({
            ...deleteUserDialogValues,
            [name]: value
        })
    }

    const handleClickDeleteUser = async () => {
        if (deleteUserDialogValues.user !== user) {
            setAlertText("Name does not match")
            setAlertDialog(true)
        } else {
            await dispatch(deleteUser(associateOID, googleID))
            await dispatch(getUsers())
            await dispatch(getEmployeesWithoutApplicationAccess())
            handleOpenDeleteUserDialog()
        }
    }

    // Delete Authentication Dialog Handlers
    const handleOpenDeleteAuthenticationDialog = (
        authenticationID,
        applicationID
    ) => {
        setSelectedAuthenticationToBeDeleted({
            authenticationID,
            applicationID
        })
        setDeleteAuthenticationDialogOpen(true)
    }

    const handleCloseDeleteAuthenticationDialog = () => {
        setSelectedAuthenticationToBeDeleted({
            authenticationID: 0,
            applicationID: 0
        })
        setDeleteAuthenticationDialogOpen(false)
    }

    const handleClickDeleteAuthentication = async () => {
        await dispatch(
            deleteAuthentication(
                selectedAuthenticationToBeDeleted,
                associateOID
            )
        )
        await dispatch(getUsers())
        handleCloseDeleteAuthenticationDialog()
    }

    // Edit Authorization Dialog Handlers
    const handleOpenEditAuthorizationDialog = async (applicationID) => {
        setSelectedApplicationToEditAuthorization(applicationID)
        await dispatch(getPermissionsForApplication(applicationID))
        setEditAuthorizationDialogOpen(true)
    }

    const handleCloseEditAuthorizationDialog = () => {
        setEditAuthorizationDialogOpen(false)
    }

    return (
        <Grid item xs={10} md={8} className={classes.User}>
            <Card>
                <Grid container>
                    {/* User Left Side */}
                    <Grid item xs={6}>
                        {/* User */}
                        <Typography variant="h6" className={classes.UserName}>
                            {user}
                        </Typography>
                    </Grid>
                    {/* User Right Side */}
                    {auth.permissions.EditUserAccess && (
                        <Grid item xs={6} align="right">
                            {/* Edit Applications Button */}
                            <Tooltip title="Edit Applications">
                                <IconButton
                                    onClick={handleOpenEditApplicationsDialog}
                                >
                                    <AppsIcon />
                                </IconButton>
                            </Tooltip>
                            {/* Edit User Button */}
                            <Tooltip title="Edit User">
                                <IconButton onClick={handleOpenEditUserDialog}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            {/* Delete User Button */}
                            <Tooltip title="Delete User">
                                <IconButton
                                    onClick={handleOpenDeleteUserDialog}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Card>

            {/* Edit Applications Dialog */}
            <Dialog
                open={editApplicationsDialogOpen}
                onClose={handleCloseEditApplicationsDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    Edit Applications
                </DialogTitle>
                <DialogContent>
                    {/* Add Authentication Field */}
                    <Grid container>
                        <Grid item xs={10}>
                            <FormControl
                                variant="outlined"
                                fullWidth={true}
                                className={classes.Dropdown}
                            >
                                <InputLabel htmlFor="applicationID">
                                    Application
                                </InputLabel>
                                <Select
                                    native
                                    value={
                                        editApplicationsDialogValues.applicationID
                                    }
                                    onChange={
                                        handleChangeEditApplicationsDialog
                                    }
                                    label="Application"
                                    inputProps={{
                                        name: "applicationID",
                                        id: "applicationID"
                                    }}
                                >
                                    <option key="none" value="none">
                                        Select an Application
                                    </option>
                                    {applications.applications
                                        .filter(
                                            (application) =>
                                                !userApplications.includes(
                                                    application.ApplicationID
                                                )
                                        )
                                        .map((application) => (
                                            <option
                                                key={application.ApplicationID}
                                                value={
                                                    application.ApplicationID
                                                }
                                            >
                                                {application.Application}
                                            </option>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Add Authentication Button */}
                        <Grid item xs={2} align="center">
                            <Tooltip title="Add New Authentication">
                                <IconButton
                                    onClick={handleClickAddNewAuthentication}
                                >
                                    <AddBoxIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {authentications.map((authentication) => (
                        <Grid container>
                            {/* Authentication */}
                            <Grid
                                item
                                xs={8}
                                sm={10}
                                style={{ margin: "auto" }}
                            >
                                <Typography variant="subtitle1">
                                    {authentication.Application}
                                </Typography>
                            </Grid>
                            {/* Edit Authorization Button */}
                            <Grid item xs={2} sm={1}>
                                <Tooltip title="Edit Authorization">
                                    <IconButton
                                        onClick={() =>
                                            handleOpenEditAuthorizationDialog(
                                                authentication.ApplicationID
                                            )
                                        }
                                    >
                                        <SecurityIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            {/* Delete Authentication Button */}
                            <Grid item xs={2} sm={1}>
                                <Tooltip title="Delete Authentication">
                                    <IconButton
                                        onClick={() =>
                                            handleOpenDeleteAuthenticationDialog(
                                                authentication.AuthenticationID,
                                                authentication.ApplicationID
                                            )
                                        }
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditApplicationsDialog}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit User Dialog */}
            <Dialog
                open={editUserDialogOpen}
                onClose={handleCloseEditUserDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
                <DialogContent>
                    {/* AssociateOID Field Display */}
                    <DialogContentText>{`AssociateOID: ${associateOID}`}</DialogContentText>
                    {/* Google ID Field */}
                    <TextField
                        margin="dense"
                        id="googleID"
                        label="Google ID"
                        type="text"
                        name="googleID"
                        value={editUserDialogValues.googleID}
                        onChange={handleChangeEditUserDialog}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditUserDialog}>Cancel</Button>
                    <Button onClick={handleClickSaveUser}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* Delete User Dialog */}
            <Dialog
                open={deleteUserDialogOpen}
                onClose={handleCloseDeleteUserDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title">Delete User</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to permanently delete this user
                        and all of their assigned applications and permissions?
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        id="user"
                        label="Enter the user's name here to confirm"
                        type="text"
                        name="user"
                        value={deleteUserDialogValues.user}
                        onChange={handleChangeDeleteUserDialog}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteUserDialog}>No</Button>
                    <Button onClick={handleClickDeleteUser}>Yes</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Authentication Dialog */}
            <Dialog
                open={deleteAuthenticationDialogOpen}
                onClose={handleCloseDeleteAuthenticationDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title">
                    Delete Authentication
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove access to this
                        application and all its permissions?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteAuthenticationDialog}>
                        No
                    </Button>
                    <Button onClick={handleClickDeleteAuthentication}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Authorization Dialog */}
            <Dialog
                open={editAuthorizationDialogOpen}
                onClose={handleCloseEditAuthorizationDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title">
                    Edit Authorization
                </DialogTitle>
                {/* Authorizations */}
                <DialogContent>
                    <Typography
                        variant="h6"
                        className={classes.AuthorizationTitle}
                    >
                        Assigned Permissions
                    </Typography>
                    {authentications.filter(
                        (authentication) =>
                            authentication.ApplicationID ===
                            selectedApplicationToEditAuthorization
                    )[0] &&
                        authentications
                            .filter(
                                (authentication) =>
                                    authentication.ApplicationID ===
                                    selectedApplicationToEditAuthorization
                            )[0]
                            .Authorizations.map((authorization) => (
                                <Grid container>
                                    {/* Assigned Authorization */}
                                    <Grid
                                        item
                                        xs={10}
                                        sm={11}
                                        style={{ margin: "auto" }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            className={
                                                classes.AuthorizationItem
                                            }
                                        >
                                            {authorization.Permission}
                                        </Typography>
                                    </Grid>
                                    {/* Delete Authorization Button */}
                                    <Grid item xs={2} sm={1}>
                                        <Tooltip title="Delete Authorization">
                                            <IconButton
                                                onClick={async () => {
                                                    await dispatch(
                                                        deleteAuthorization(
                                                            authorization.AuthorizationID
                                                        )
                                                    )
                                                    await dispatch(getUsers())
                                                }}
                                            >
                                                <CloseIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            ))}
                </DialogContent>
                <DialogContent>
                    <Typography
                        variant="h6"
                        className={classes.AuthorizationTitle}
                    >
                        Unassigned Permissions
                    </Typography>
                    {permissions.selectedApplicationPermissions
                        .filter(
                            (permission) =>
                                !userPermissions.includes(
                                    permission.PermissionID
                                )
                        )
                        .map((permission) => (
                            <Grid container>
                                {/* Unassigned Authorization */}
                                <Grid
                                    item
                                    xs={10}
                                    sm={11}
                                    style={{ margin: "auto" }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.AuthorizationItem}
                                    >
                                        {permission.Permission}
                                    </Typography>
                                </Grid>
                                {/* Add Authorization Button */}
                                <Grid item xs={2} sm={1}>
                                    <Tooltip title="Add Authorization">
                                        <IconButton
                                            onClick={async () => {
                                                await dispatch(
                                                    createAuthorization({
                                                        AssociateOID:
                                                            associateOID,
                                                        ApplicationID:
                                                            permission.ApplicationID,
                                                        PermissionID:
                                                            permission.PermissionID
                                                    })
                                                )
                                                await dispatch(getUsers())
                                            }}
                                        >
                                            <AddIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditAuthorizationDialog}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Alert Dialog */}
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={alertDialog}
                    autoHideDuration={5000}
                    onClose={handleAlertDialogClose}
                >
                    <Alert onClose={handleAlertDialogClose} severity="error">
                        {alertText}
                    </Alert>
                </Snackbar>
            </div>
        </Grid>
    )
}

export default User
