import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Grid,
    TextField,
    Typography,
    CircularProgress
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useStyles from "./styles/UserAccessPageStyles"
import User from "./User"
import NewUser from "./NewUser"
import NewFirebaseUser from "./NewFirebaseUser"
import LoginPrompt from "../login/LoginPrompt"
import { getUsers } from "../../actions/userActions"
import { getApplications } from "../../actions/applicationActions"
import { getEmployeesWithoutApplicationAccess } from "../../actions/employeeActions"
import { getPendingUsers } from "../../actions/pendingUserActions"

function UserAccessPage() {
    const classes = useStyles()
    const dispatch = useDispatch()

    // Store Variables
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const authenticated = useSelector((state) => state.auth.authenticated)
    const permissions = useSelector((state) => state.auth.permissions)
    const users = useSelector((state) => state.users.users)

    // User Search State
    const [userSearch, setUserSearch] = useState("")

    useEffect(() => {
        if (loggedIn) {
            async function runUseEffectFunctions() {
                await dispatch(getUsers())
                await dispatch(getApplications())
                await dispatch(getEmployeesWithoutApplicationAccess())
                await dispatch(getPendingUsers())
            }
            runUseEffectFunctions()
        }
    }, [dispatch, loggedIn])

    // User Search Handlers
    const handleChangeUserSearch = (event, value) => {
        setUserSearch(value)
    }

    return (
        <>
            {authenticated ? (
                <Grid container justify="center">
                    <Grid item xs={10} md={8} align="center">
                        <Typography variant="h5" align="center">
                            Users
                        </Typography>
                        {/* New Firebase User Button */}
                        {permissions.EditUserAccess && <NewFirebaseUser />}
                        {/* New User Button */}
                        {permissions.EditUserAccess && <NewUser />}
                        {/* User Search */}
                        <Autocomplete
                            freeSolo
                            disablePortal
                            id="userSearch"
                            options={users.map((user) => user.User).sort()}
                            renderInput={(params) => (
                                <TextField {...params} label="User Search" />
                            )}
                            name="userSearch"
                            onChange={handleChangeUserSearch}
                            className={classes.UserSearch}
                        />
                    </Grid>

                    {/* Users */}
                    {permissions.ViewUserAccess && users.length === 0 && (
                        <Grid item xs={10} md={8} align="center">
                            <CircularProgress />
                        </Grid>
                    )}
                    {permissions.ViewUserAccess &&
                        users
                            .filter(
                                (user) =>
                                    !userSearch ||
                                    user.User.toLowerCase().includes(
                                        userSearch.toLowerCase()
                                    )
                            )
                            .map((user) => {
                                const userApplications =
                                    user.Authentications.map(
                                        (authentication) =>
                                            authentication.ApplicationID
                                    )

                                let userPermissions = []
                                user.Authentications.forEach(
                                    (authentication) => {
                                        authentication.Authorizations.map(
                                            (authorization) =>
                                                userPermissions.push(
                                                    authorization.PermissionID
                                                )
                                        )
                                    }
                                )

                                return (
                                    <User
                                        key={user.AssociateOID}
                                        associateOID={user.AssociateOID}
                                        user={user.User}
                                        authentications={user.Authentications}
                                        userApplications={userApplications}
                                        userPermissions={userPermissions}
                                        darkMode={user.DarkMode}
                                        googleID={user.GoogleID}
                                    />
                                )
                            })}
                </Grid>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default UserAccessPage
