import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "../../auth/axiosAuth"
import { backendURL } from "../../config"
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    IconButton,
    Tooltip,
    DialogContentText,
    FormLabel
} from "@material-ui/core"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import SecurityIcon from "@mui/icons-material/Security"
import AddBoxIcon from "@mui/icons-material/AddBox"
import useStyles from "./styles/ApplicationStyles"
import Alert from "../main/Alert"
import {
    getApplications,
    updateApplication,
    deleteApplication
} from "../../actions/applicationActions"
import {
    getPermissionsForApplication,
    createPermission,
    deletePermission
} from "../../actions/permissionActions"

function Application(props) {
    const classes = useStyles()
    const dispatch = useDispatch()

    // Props
    const {
        applicationID,
        application,
        applicationURL,
        releaseNotesURL,
        applicationOwnerAOID,
        applicationOwner,
        applicationIsVisible,
        majorVersion,
        minorVersion,
        fixVersion
    } = props

    // Store Variables
    const auth = useSelector((state) => state.auth)
    const employees = useSelector((state) => state.employees)
    const permissions = useSelector((state) => state.permissions)

    // Alert State
    const [alertDialog, setAlertDialog] = useState(false)
    const [alertText, setAlertText] = useState("")

    // Edit Application Dialog State
    const [editApplicationDialogOpen, setEditApplicationDialogOpen] =
        useState(false)
    const [editApplicationDialogValues, setEditApplicationDialogValues] =
        useState({
            applicationID: "",
            application: "",
            applicationURL: "",
            releaseNotesURL: "",
            applicationOwnerAOID: "",
            majorVersion: "",
            minorVersion: "",
            fixVersion: ""
        })
    const [applicationVisibility, setApplicationVisibility] = useState(true)

    // Delete Application Dialog State
    const [deleteApplicationDialogOpen, setDeleteApplicationDialogOpen] =
        useState(false)

    // Edit Permissions Dialog State
    const [editPermissionsDialogOpen, setEditPermissionsDialogOpen] =
        useState(false)
    const [editPermissionsDialogValues, setEditPermissionsDialogValues] =
        useState({
            newPermission: ""
        })

    // Delete Permission Dialog State
    const [deletePermissionDialogOpen, setDeletePermissionDialogOpen] =
        useState(false)
    const [selectedPermissionToBeDeleted, setSelectedPermissionToBeDeleted] =
        useState(0)

    // Alert Handlers
    const handleAlertDialogClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertDialog(false)
    }

    // Edit Application Dialog Handlers
    const handleOpenEditApplicationDialog = () => {
        setEditApplicationDialogValues({
            applicationID,
            application,
            applicationURL,
            releaseNotesURL,
            applicationOwnerAOID,
            majorVersion,
            minorVersion,
            fixVersion
        })
        setApplicationVisibility(applicationIsVisible)
        setEditApplicationDialogOpen(true)
    }

    const handleCloseEditApplicationDialog = () => {
        setEditApplicationDialogOpen(false)
        clearEditApplicationDialogValues()
    }

    const handleChangeEditApplicationDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setEditApplicationDialogValues({
            ...editApplicationDialogValues,
            [name]: value
        })
    }

    const handleChangeApplicationVisibility = (event, newVisibility) => {
        if (newVisibility !== null) {
            setApplicationVisibility(newVisibility)
        }
    }

    const clearEditApplicationDialogValues = () => {
        setEditApplicationDialogValues({
            applicationID: "",
            application: "",
            applicationURL: "",
            releaseNotesURL: "",
            applicationOwnerAOID: "",
            majorVersion: "",
            minorVersion: "",
            fixVersion: ""
        })
    }

    const handleClickSaveApplication = async () => {
        if (editApplicationDialogValues.application === "") {
            setAlertText("Application field must be filled out")
            setAlertDialog(true)
        } else {
            const updatedApplication = {
                ApplicationID: editApplicationDialogValues.applicationID,
                Application: editApplicationDialogValues.application,
                ApplicationURL: editApplicationDialogValues.applicationURL,
                ReleaseNotesURL: editApplicationDialogValues.releaseNotesURL,
                ApplicationOwnerAOID:
                    editApplicationDialogValues.applicationOwnerAOID,
                ApplicationIsVisible: applicationVisibility,
                MajorVersion: editApplicationDialogValues.majorVersion,
                MinorVersion: editApplicationDialogValues.minorVersion,
                FixVersion: editApplicationDialogValues.fixVersion
            }
            await dispatch(updateApplication(updatedApplication))
            await dispatch(getApplications())
            setEditApplicationDialogOpen(false)
            clearEditApplicationDialogValues()
        }
    }

    // Delete Application Dialog Handlers
    const handleOpenDeleteApplicationDialog = () => {
        setDeleteApplicationDialogOpen(true)
    }

    const handleCloseDeleteApplicationDialog = () => {
        setDeleteApplicationDialogOpen(false)
    }

    const handleClickDeleteApplication = async () => {
        let applicationCannotBeDeleted = false
        const authentications = await axios.get(
            `${backendURL}/authentication/application/${applicationID}`
        )
        const permissions = await axios.get(
            `${backendURL}/permission/application/${applicationID}`
        )

        if (authentications.data.length > 0 || permissions.data.length > 0) {
            applicationCannotBeDeleted = true
        }

        console.log(applicationID)
        console.log(authentications)

        if (applicationCannotBeDeleted) {
            setAlertText(
                "Cannot delete application with assigned users or permissions"
            )
            setAlertDialog(true)
            setDeleteApplicationDialogOpen(false)
        } else {
            await dispatch(deleteApplication(applicationID))
            await dispatch(getApplications())
        }
    }

    // Edit Permissions Dialog Handlers
    const handleOpenEditPermissionsDialog = async () => {
        setEditPermissionsDialogOpen(true)
        await dispatch(getPermissionsForApplication(applicationID))
    }

    const handleCloseEditPermissionsDialog = () => {
        setEditPermissionsDialogOpen(false)
        clearEditPermissionsDialogValues()
    }

    const handleChangeEditPermissionsDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setEditPermissionsDialogValues({
            ...editPermissionsDialogValues,
            [name]: value
        })
    }

    const clearEditPermissionsDialogValues = () => {
        setEditPermissionsDialogValues({
            newPermission: ""
        })
    }

    const handleClickAddNewPermission = async () => {
        if (editPermissionsDialogValues.newPermission.trim() === "") {
            setAlertText("New Permission field must be filled out")
            setAlertDialog(true)
        } else if (
            permissions.selectedApplicationPermissions.filter(
                (permission) =>
                    permission.Permission.toLowerCase() ===
                    editPermissionsDialogValues.newPermission
                        .trim()
                        .toLowerCase()
            ).length > 0
        ) {
            setAlertText("This permission already exists")
            setAlertDialog(true)
        } else {
            const newPermission = {
                ApplicationID: applicationID,
                Permission: editPermissionsDialogValues.newPermission.trim()
            }
            await dispatch(createPermission(newPermission))
            clearEditPermissionsDialogValues()
            await dispatch(getPermissionsForApplication(applicationID))
        }
    }

    // Delete Permission Dialog Handlers
    const handleOpenDeletePermissionDialog = (permissionID) => {
        setDeletePermissionDialogOpen(true)
        setSelectedPermissionToBeDeleted(permissionID)
    }

    const handleCloseDeletePermissionDialog = () => {
        setDeletePermissionDialogOpen(false)
        setSelectedPermissionToBeDeleted(0)
    }

    const handleClickDeletePermission = async () => {
        let permissionCannotBeDeleted = false
        const authorizations = await axios.get(
            `${backendURL}/authorization/permission/${selectedPermissionToBeDeleted}`
        )

        if (authorizations.data.length > 0) {
            permissionCannotBeDeleted = true
        }

        if (permissionCannotBeDeleted) {
            setAlertText("Cannot delete permission with assigned users")
            setAlertDialog(true)
            setDeleteApplicationDialogOpen(false)
        } else {
            await dispatch(deletePermission(selectedPermissionToBeDeleted))
            await dispatch(getPermissionsForApplication(applicationID))
        }
        setDeletePermissionDialogOpen(false)
    }

    return (
        <Grid item xs={10} md={8} className={classes.Application}>
            <Card>
                <Grid container>
                    {/* Application Left Side */}
                    <Grid item xs={12} md={6}>
                        <CardContent>
                            {/* Application Title */}
                            <Typography
                                variant="h6"
                                className={classes.FirstApplicationItem}
                            >
                                {application}
                            </Typography>
                            {/* Application ID */}
                            <Typography
                                variant="subtitle2"
                                className={classes.FirstApplicationItem}
                            >
                                ID - {applicationID}
                            </Typography>
                            {/* Application URL */}
                            <Typography
                                variant="subtitle2"
                                className={classes.ApplicationItem}
                            >
                                URL - {applicationURL}
                            </Typography>
                            {/* Release Notes URL */}
                            <Typography
                                variant="subtitle2"
                                className={classes.ApplicationItem}
                            >
                                Release Notes -{" "}
                                {releaseNotesURL &&
                                releaseNotesURL.includes("sharepoint")
                                    ? "SharePoint Folder"
                                    : releaseNotesURL}
                            </Typography>
                            {/* Application Owner */}
                            <Typography
                                variant="subtitle2"
                                className={classes.ApplicationItem}
                            >
                                Owner - {applicationOwner}
                            </Typography>
                            {/* Application Version */}
                            <Typography
                                variant="subtitle2"
                                className={classes.ApplicationItem}
                            >
                                Version -{" "}
                                {`${majorVersion}.${minorVersion}.${fixVersion}`}
                            </Typography>
                            {/* Application Is Visible */}
                            <Typography
                                variant="subtitle2"
                                className={classes.LastApplicationItem}
                            >
                                Visible -{" "}
                                {applicationIsVisible ? "True" : "False"}
                            </Typography>
                        </CardContent>
                    </Grid>
                    {/* Application Right Side */}
                    {auth.permissions.EditApplications && (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            align="right"
                            className={classes.ApplicationButtons}
                        >
                            {/* Edit Permissions Button */}
                            <Tooltip title="Edit Permissions">
                                <IconButton
                                    onClick={handleOpenEditPermissionsDialog}
                                >
                                    <SecurityIcon />
                                </IconButton>
                            </Tooltip>
                            {/* Edit Application Button */}
                            <Tooltip title="Edit Application">
                                <IconButton
                                    onClick={handleOpenEditApplicationDialog}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            {/* Delete Application Button */}
                            <Tooltip title="Delete Application">
                                <IconButton
                                    onClick={handleOpenDeleteApplicationDialog}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Card>

            {/* Edit Application Dialog */}
            <Dialog
                open={editApplicationDialogOpen}
                onClose={handleCloseEditApplicationDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    Edit Application
                </DialogTitle>
                <DialogContent>
                    {/* Application Field */}
                    <TextField
                        margin="dense"
                        id="application"
                        label="Application"
                        type="text"
                        name="application"
                        value={editApplicationDialogValues.application}
                        onChange={handleChangeEditApplicationDialog}
                        fullWidth
                    />
                    {/* Applicaiton URL Field */}
                    <TextField
                        margin="dense"
                        id="applicationURL"
                        label="Application URL"
                        type="text"
                        name="applicationURL"
                        value={editApplicationDialogValues.applicationURL}
                        onChange={handleChangeEditApplicationDialog}
                        fullWidth
                    />
                    {/* Release Notes URL Field */}
                    <TextField
                        margin="dense"
                        id="releaseNotesURL"
                        label="Release Notes URL"
                        type="text"
                        name="releaseNotesURL"
                        value={editApplicationDialogValues.releaseNotesURL}
                        onChange={handleChangeEditApplicationDialog}
                        fullWidth
                    />
                    {/* Application Owner Field */}
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        className={classes.Dropdown}
                    >
                        <InputLabel htmlFor="applicationOwnerAOID">
                            Application Owner
                        </InputLabel>
                        <Select
                            native
                            value={
                                editApplicationDialogValues.applicationOwnerAOID
                            }
                            onChange={handleChangeEditApplicationDialog}
                            label="Application Owner"
                            inputProps={{
                                name: "applicationOwnerAOID",
                                id: "applicationOwnerAOID"
                            }}
                        >
                            <option key="none" value="none">
                                Select an Application Owner
                            </option>
                            {employees.activeEmployees.map((employee) => (
                                <option
                                    key={employee.AssociateOID}
                                    value={employee.AssociateOID}
                                >
                                    {`${employee.FirstName} ${employee.LastName}`}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <Grid container>
                        <Grid item xs={4}>
                            {/* Version Fields */}
                            <TextField
                                margin="dense"
                                id="majorVersion"
                                label="Major Version"
                                variant="outlined"
                                type="number"
                                name="majorVersion"
                                value={editApplicationDialogValues.majorVersion}
                                onChange={handleChangeEditApplicationDialog}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                margin="dense"
                                id="minorVersion"
                                label="Minor Version"
                                variant="outlined"
                                type="number"
                                name="minorVersion"
                                value={editApplicationDialogValues.minorVersion}
                                onChange={handleChangeEditApplicationDialog}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                margin="dense"
                                id="fixVersion"
                                label="Fix Version"
                                variant="outlined"
                                type="number"
                                name="fixVersion"
                                value={editApplicationDialogValues.fixVersion}
                                onChange={handleChangeEditApplicationDialog}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    {/* Application Is Visible Field */}
                    <FormControl className={classes.Toggle}>
                        <FormLabel>Application Is Visible</FormLabel>
                        <br />
                        <ToggleButtonGroup
                            value={applicationVisibility}
                            exclusive
                            onChange={handleChangeApplicationVisibility}
                            aria-label="application is visible"
                        >
                            <ToggleButton value={true} aria-label="yes">
                                Yes
                            </ToggleButton>
                            <ToggleButton value={false} aria-label="no">
                                No
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditApplicationDialog}>
                        Cancel
                    </Button>
                    <Button onClick={handleClickSaveApplication}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Application Dialog */}
            <Dialog
                open={deleteApplicationDialogOpen}
                onClose={handleCloseDeleteApplicationDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    Delete Application
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to permanently delete{" "}
                        {application}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteApplicationDialog}>
                        No
                    </Button>
                    <Button onClick={handleClickDeleteApplication}>Yes</Button>
                </DialogActions>
            </Dialog>

            {/* Edit Permissions Dialog */}
            <Dialog
                open={editPermissionsDialogOpen}
                onClose={handleCloseEditPermissionsDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">
                    Edit Permissions
                </DialogTitle>
                <DialogContent>
                    {/* New Permission Field */}
                    <Grid container>
                        <Grid item xs={10}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="newPermission"
                                label="New Permission"
                                type="text"
                                name="newPermission"
                                value={
                                    editPermissionsDialogValues.newPermission
                                }
                                onChange={handleChangeEditPermissionsDialog}
                                fullWidth
                            />
                        </Grid>
                        {/* Add New Permission Button */}
                        <Grid item xs={2} align="center">
                            <Tooltip title="Add New Permission">
                                <IconButton
                                    onClick={handleClickAddNewPermission}
                                >
                                    <AddBoxIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {/* Permissions */}
                    {permissions.selectedApplicationPermissions.map(
                        (permission) => (
                            <Grid container>
                                {/* Permission */}
                                <Grid
                                    item
                                    xs={10}
                                    sm={11}
                                    style={{ margin: "auto" }}
                                >
                                    <Typography variant="subtitle1">
                                        {permission.Permission}
                                    </Typography>
                                </Grid>
                                {/* Delete Permission Button */}
                                <Grid item xs={2} sm={1}>
                                    <Tooltip title="Delete Permission">
                                        <IconButton
                                            onClick={() =>
                                                handleOpenDeletePermissionDialog(
                                                    permission.PermissionID
                                                )
                                            }
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditPermissionsDialog}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Permission Dialog */}
            <Dialog
                open={deletePermissionDialogOpen}
                onClose={handleCloseDeletePermissionDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title">
                    Delete Permission
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to permanently delete this
                        permission?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeletePermissionDialog}>
                        No
                    </Button>
                    <Button onClick={handleClickDeletePermission}>Yes</Button>
                </DialogActions>
            </Dialog>

            {/* Alert Dialog */}
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={alertDialog}
                    autoHideDuration={5000}
                    onClose={handleAlertDialogClose}
                >
                    <Alert onClose={handleAlertDialogClose} severity="error">
                        {alertText}
                    </Alert>
                </Snackbar>
            </div>
        </Grid>
    )
}

export default Application
