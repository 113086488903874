import { CREATE_AUDIT_TRAIL } from "../actions/types"

const initialState = {}

const auditTrailReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_AUDIT_TRAIL:
            return { ...state }
        default:
            return state
    }
}

export default auditTrailReducer
