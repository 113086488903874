import {
    CREATE_APPLICATION,
    GET_APPLICATIONS,
    UPDATE_APPLICATION,
    DELETE_APPLICATION
} from "../actions/types"

const initialState = {
    applications: []
}

const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATIONS:
            return { ...state, applications: action.payload }
        case CREATE_APPLICATION:
            return { ...state }
        case UPDATE_APPLICATION:
            return { ...state }
        case DELETE_APPLICATION:
            return { ...state }
        default:
            return state
    }
}

export default applicationReducer
