import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    NewUserButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    EmployeeNameDropdown: {
        marginBottom: theme.spacing(4)
    },
    PendingUserDropdown: {
        marginTop: theme.spacing(4)
    },
    OR: {
        marginTop: theme.spacing(5)
    }
}))

export default useStyles
