import {
    GET_EMPLOYEES,
    GET_ACTIVE_EMPLOYEES,
    GET_EMPLOYEES_WITHOUT_APPLICATION_ACCESS
} from "../actions/types"

const initialState = {
    employees: [],
    activeEmployees: [],
    employeesWithoutApplicationAccess: []
}

const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEES:
            return { ...state, employees: action.payload }
        case GET_ACTIVE_EMPLOYEES:
            return { ...state, activeEmployees: action.payload }
        case GET_EMPLOYEES_WITHOUT_APPLICATION_ACCESS:
            return {
                ...state,
                employeesWithoutApplicationAccess: action.payload
            }
        default:
            return state
    }
}

export default employeeReducer
