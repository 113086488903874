import { combineReducers } from "redux"
import authReducer from "./authReducer"
import applicationReducer from "./applicationReducer"
import permissionReducer from "./permissionReducer"
import employeeReducer from "./employeeReducer"
import userReducer from "./userReducer"
import pendingUserReducer from "./pendingUserReducer"
import auditTrailReducer from "./auditTrailReducer"

export default combineReducers({
    auth: authReducer,
    applications: applicationReducer,
    permissions: permissionReducer,
    employees: employeeReducer,
    users: userReducer,
    pendingUsers: pendingUserReducer,
    auditTrail: auditTrailReducer
})
