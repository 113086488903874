import {
    GET_EMPLOYEES,
    GET_ACTIVE_EMPLOYEES,
    GET_EMPLOYEES_WITHOUT_APPLICATION_ACCESS
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getEmployees = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/employee`)
    dispatch({ type: GET_EMPLOYEES, payload: res.data })
}

export const getActiveEmployees = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/employee/active`)
    dispatch({ type: GET_ACTIVE_EMPLOYEES, payload: res.data })
}

export const getEmployeesWithoutApplicationAccess = () => async (dispatch) => {
    const employees = await axios.get(`${backendURL}/employee/active`)
    const users = await axios.get(`${backendURL}/user`)
    const associateOIDs = users.data.map((user) => user.AssociateOID)

    const employeesWithoutApplicationAccess = employees.data.filter(
        (employee) => !associateOIDs.includes(employee.AssociateOID)
    )

    dispatch({
        type: GET_EMPLOYEES_WITHOUT_APPLICATION_ACCESS,
        payload: employeesWithoutApplicationAccess
    })
}
