export const GET_USER_DATA = "GET_USER_DATA"
export const GET_AUTHORIZATION_FOR_APPLICATION =
    "GET_AUTHORIZATION_FOR_APPLICATION"
export const TOGGLE_DARKMODE = "TOGGLE_DARKMODE"
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGIN_USER = "LOGIN_USER"

export const CREATE_AUDIT_TRAIL = "CREATE_AUDIT_TRAIL"

export const GET_EMPLOYEES = "GET_EMPLOYEES"
export const GET_ACTIVE_EMPLOYEES = "GET_ACTIVE_EMPLOYEES"
export const GET_EMPLOYEES_WITHOUT_APPLICATION_ACCESS =
    "GET_EMPLOYEES_WITHOUT_APPLICATION_ACCESS"

export const GET_APPLICATIONS = "GET_APPLICATIONS"
export const CREATE_APPLICATION = "CREATE_APPLICATION"
export const UPDATE_APPLICATION = "UPDATE_APPLICATION"
export const DELETE_APPLICATION = "DELETE_APPLICATION"

export const GET_PERMISSIONS_FOR_APPLICATION = "GET_PERMISSIONS_FOR_APPLICATION"
export const CREATE_PERMISSION = "CREATE_PERMISSION"
export const DELETE_PERMISSION = "DELETE_PERMISSION"

export const GET_USERS = "GET_USERS"
export const CREATE_USER = "CREATE_USER"
export const CREATE_FIREBASE_USER = "CREATE_FIREBASE_USER"
export const UPDATE_USER = "UPDATE_USER"
export const DELETE_USER = "DELETE_USER"
export const CREATE_AUTHENTICATION = "CREATE_AUTHENTICATION"
export const DELETE_AUTHENTICATION = "DELETE_AUTHENTICATION"
export const CREATE_AUTHORIZATION = "CREATE_AUTHORIZATION"
export const DELETE_AUTHORIZATION = "DELETE_AUTHORIZATION"

export const GET_PENDING_USERS = "GET_PENDING_USERS"
export const CREATE_PENDING_USER = "CREATE_PENDING_USER"
export const DELETE_PENDING_USER = "DELETE_PENDING_USER"
