import {
    CREATE_APPLICATION,
    GET_APPLICATIONS,
    UPDATE_APPLICATION,
    DELETE_APPLICATION
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getApplications = () => async (dispatch) => {
    const applications = await axios.get(`${backendURL}/application`)
    const employees = await axios.get(`${backendURL}/employee`)

    const applicationsWithEmployees = applications.data.map((application) => {
        const employee = employees.data.filter(
            (employee) =>
                employee.AssociateOID === application.ApplicationOwnerAOID
        )[0]

        if (employee) {
            const employeeName = `${employee.FirstName} ${employee.LastName}`
            return { ...application, ApplicationOwner: employeeName }
        } else {
            return { ...application, ApplicationOwner: "none" }
        }
    })

    dispatch({ type: GET_APPLICATIONS, payload: applicationsWithEmployees })
}

export const createApplication = (newApplication) => async (dispatch) => {
    await axios.post(`${backendURL}/application`, newApplication)
    dispatch({ type: CREATE_APPLICATION })
}

export const updateApplication = (updatedApplication) => async (dispatch) => {
    await axios.put(`${backendURL}/application`, updatedApplication)
    dispatch({ type: UPDATE_APPLICATION })
}

export const deleteApplication = (applicationID) => async (dispatch) => {
    await axios.delete(`${backendURL}/application/${applicationID}`)
    dispatch({ type: DELETE_APPLICATION })
}
