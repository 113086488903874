import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Grid, CircularProgress } from "@material-ui/core"
import NewApplication from "./NewApplication"
import Application from "./Application"
import LoginPrompt from "../login/LoginPrompt"
import { getApplications } from "../../actions/applicationActions"
import { getActiveEmployees } from "../../actions/employeeActions"

function ApplicationsPage() {
    const dispatch = useDispatch()

    // Store Variables
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const authenticated = useSelector((state) => state.auth.authenticated)
    const permissions = useSelector((state) => state.auth.permissions)
    const applications = useSelector((state) => state.applications.applications)

    useEffect(() => {
        if (loggedIn) {
            async function runUseEffectFunctions() {
                await dispatch(getApplications())
                await dispatch(getActiveEmployees())
            }
            runUseEffectFunctions()
        }
        return () => {}
    }, [dispatch, loggedIn])

    return (
        <>
            {authenticated ? (
                <Grid container justify="center">
                    {/* New Application */}
                    {permissions.EditApplications && <NewApplication />}

                    {/* Applications */}
                    {permissions.ViewApplications &&
                        applications.length === 0 && (
                            <Grid item xs={10} md={8} align="center">
                                <CircularProgress />
                            </Grid>
                        )}
                    {permissions.ViewApplications &&
                        applications.map((application) => (
                            <Application
                                key={application.ApplicationID}
                                applicationID={application.ApplicationID}
                                application={application.Application}
                                applicationURL={application.ApplicationURL}
                                releaseNotesURL={application.ReleaseNotesURL}
                                applicationOwnerAOID={
                                    application.ApplicationOwnerAOID
                                }
                                applicationOwner={application.ApplicationOwner}
                                applicationIsVisible={
                                    application.ApplicationIsVisible
                                }
                                majorVersion={application.MajorVersion}
                                minorVersion={application.MinorVersion}
                                fixVersion={application.FixVersion}
                            />
                        ))}
                </Grid>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default ApplicationsPage
